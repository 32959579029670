// We could require all the Bootstrap JS plugins with
// import * as bootstrap from 'bootstrap';
// but in order to reduce the bundle size, this file only requires
// the plugins that are used
import { Tooltip } from 'bootstrap';

import loadTableSort from './lib/tablesort';
import flatpickr from './lib/flatpickr';
import doTheTinyThing from './lib/tinyMce';

import './application';

// Document Ready
// https://stackoverflow.com/questions/9899372/pure-javascript-equivalent-of-jquerys-ready-how-to-call-a-function-when-t
function docReady(fn) {
  if (document.readyState === 'complete' || document.readyState === 'interactive') {
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

function setMainProFrom(event) {
  // eslint-disable-next-line no-underscore-dangle
  document.getElementById('filters_session_start_date_from')._flatpickr.set('maxDate', event.target.value);
}

function setMainProTo(event) {
  // eslint-disable-next-line no-underscore-dangle
  document.getElementById('filters_session_start_date_to')._flatpickr.set('minDate', event.target.value);
}

function mainProListeners() {
  document.getElementById('filters_session_start_date_from').addEventListener('change', setMainProTo, false);
  document.getElementById('filters_session_start_date_to').addEventListener('change', setMainProFrom, false);
}

docReady(() => {
  function addFields(e) {
    e.preventDefault();
    const time = new Date().getTime();
    const regexp = new RegExp(this.dataset.id, 'g');
    const htmlString = this.dataset.fields.replace(regexp, time);
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    div.getElementsByClassName('remove_fields')[0].addEventListener('click', removeFields, false);
    this.parentNode.insertBefore(div, this);
  }

  function removeEmptyMessages() {
    if (document.getElementsByClassName('nested-attr-empty-message').length > 0) {
      const element = document.getElementsByClassName('nested-attr-empty-message')[0];
      element.parentNode.removeChild(element);
    }
  }

  function removeFields(e) {
    e.preventDefault();
    this.parentNode.querySelector('input[type=hidden]').value = 1;
    this.closest('fieldset').style.display = 'none';
  }

  // Add event listeners for Add and Remove buttons
  if (document.getElementsByClassName('add_fields')[0]) {
    document.getElementsByClassName('add_fields')[0].addEventListener('click', addFields, false);
    document.getElementsByClassName('add_fields')[0].addEventListener('click', removeEmptyMessages, false);
  }

  const initialRemoveLinks = document.getElementsByClassName('remove_fields');
  for (let z = 0; z < initialRemoveLinks.length; z += 1) {
    const elem = initialRemoveLinks[z];
    elem.addEventListener('click', removeFields, false);
  }

  loadTableSort();
  flatpickr();
  doTheTinyThing();

  // Enable BS5 Tooltips
  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((el) => new Tooltip(el));

  // Mainpro Listeners for Date restriction logic
  if (document.getElementById('main-pro-report-page')) { mainProListeners(); }
});

// Add loading spinner to long-loading pages
docReady(() => {
  document.querySelectorAll('.js-loader').forEach((link) => {
    link.addEventListener('click', (event) => {
      const element = event.currentTarget;
      if (!element.classList.contains('loading')) {
        setTimeout(() => {
          element.setAttribute('aria-disabled', true);
          element.dataset.href = element.getAttribute('href');
          element.removeAttribute('href');

          element.classList.add('loading', 'pe-none');
          const spinner = document.createElement('div');
          spinner.innerHTML = `<div class="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-secondary opacity-50 text-light">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>`;
          element.appendChild(spinner);
        }, 0);

        // find other loading links and remove the loader
        document.querySelectorAll('.js-loader.loading').forEach((loadingLink) => {
          loadingLink.classList.remove('loading', 'pe-none');
          loadingLink.setAttribute('href', loadingLink.dataset.href);
          loadingLink.removeAttribute('data-href');
          loadingLink.removeAttribute('aria-disabled');
          loadingLink.querySelector('.position-absolute').remove();
        });
      }
    });
  });
});
