import { Controller } from '@hotwired/stimulus';
import _debounce from 'lodash/debounce';

export default class extends Controller {
  initialize() {
    this.submit = _debounce(this.submit.bind(this), 200);
  }

  reset() {
    Array.from(this.element.elements).forEach((element) => {
      element.value = '';
      if (element.type === 'select-one') {
        if (element.dataset.default) {
          element.value = element.dataset.default;
        } else {
          element.selectedIndex = 0;
        }
      }
    });
    this.submit();
  }

  submit() {
    this.element.requestSubmit();
  }
}
